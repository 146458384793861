@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.sidebar-main {
    width: 127px; 
    height: 100vh;
    background-color: #0C0C0C;
    font-family: "Inter", sans-serif;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    position: relative;
    box-shadow: 0px 4px 4px 0px #00000040;
}

.sidebar-main .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 14px;
    background-color: #090909;
    cursor: pointer;
    border-radius: 20px;
}

.sidebar-main .logo img {
    margin-right: 13px;
    max-height: 28px;
}

.sidebar-main .logo-text,
.sidebar-main .nav .nav-item .nav-link {
    font-size: 13px;
    line-height: 15.73px;
    font-weight: 400;
    color: #E7E7E7;
}

.sidebar-main .nav {
    margin-top: 34px;
}

.sidebar-main .nav .nav-item  {
    margin-bottom: 40px;
}

.sidebar-main .nav .nav-item .nav-link {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
}

.sidebar-main .nav .nav-item .nav-link .icon {
    margin-right: 20px;
}

.sidebar-main .nav .nav-item .nav-link .icon img {
    max-height: 25px;
}

.sidebar-main::after {
    content: '';
    background: #0000004F;
    position: absolute;
    left: 0;
    right: 0;
    width: 52px;
    height: 100%;
    top: 0;
}