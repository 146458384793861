.block {
  opacity: 1;
  stroke: 8px #fff;
  fill: #ff0000;
}
@media (min-width: 46.25em) {
  .block.is-hover {
    fill: #e9d819;
  }
  .block.is-available:hover {
    fill: #c6c918;
  }
  .block.is-available.is-filtered:hover {
    fill: #78b720;
  }
}
.block.showseats {
  opacity: 0;
}
.block.showseats:hover,
.block.showseats.is-filtered:hover {
  opacity: 0.4;
  fill: #df1d1d;
}
.block.is-multiselected {
  fill: #262626;
}
.block.is-multiselected.is-picked,
.block.is-multiselected.is-picked:hover {
  fill: #dca817;
}
.block.is-multiselected.is-not-picked,
.block.is-multiselected.is-not-picked:hover {
  fill: #ff2323;
}
.block.is-available {
  fill: #ce0b0b;
}
.block.is-available.is-filtered {
  fill: #026cdf;
}
.block.is-available.is-picked,
.block.is-available.is-picked:hover {
  fill: #026cdf;
}
.block.is-available.is-not-picked,
.block.is-available.is-not-picked:hover {
  fill: #e30f0f;
}
.block.is-filtered.minimum {
  fill: #026cdf;
}
.block.is-filtered.middle {
  fill: #026cdf;
}
.block.is-filtered.maximum {
  fill: #b81ee3;
}
.block.is-filtered.is-picked,
.block.is-filtered.is-picked:hover {
  fill: #4794e7;
}
.block.is-filtered.is-not-picked,
.block.is-filtered.is-not-picked:hover {
  fill: #fd0000;
}

.seatmap-container--sim .minimap .label {
  display: none;
}

.label {
  font-family: "Averta", "TM Sans", arial, "lucida console", sans-serif;
  pointer-events: none;
  fill: #fff;
}
.label tspan {
  text-anchor: middle;
}
.label.showseats {
  display: none;
}
.block {
  -webkit-transition: opacity 0.5s, fill 0.5s;
  transition: opacity 0.5s, fill 0.5s;
  opacity: 0.75;
  fill: #ccc;
  stroke: white;
  stroke-width: 8px;
}
.block.is-available {
  fill: #ccc;
}
.block.showseats {
  opacity: 0.15;
  fill: #fff;
}
.block.is-ga {
  cursor: pointer;
}
.block.is-multiselected {
  fill: #475058;
}
.map {
  overflow: hidden;
  position: relative;
  border: 1px solid #ccc;
}
.map__svg,
.map__svg--static {
  background: center center no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
}
.map__svg--static .block,
.map__svg--static .block.is-ga {
  cursor: initial;
}
.seatmap .map {
  width: 100%;
  height: 100%;
}

.map {
  padding: 15px 0 15px 0;
  height: 100%;
  border: 0;
}

.map__wrapper {
  height: 100%;
}

.seatmap-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.seatmap-container--sim .minimap {
  position: absolute;
  top: 1.5rem;
  right: 15px;
  background: rgba(38, 38, 38, 0.5);
  border: 0;
  border-radius: 3px;
}
@media (min-width: 46.25em) {
  .seatmap-container--sim .minimap {
    right: 70px;
  }
}

.seatmap-container--sim .minimap .block {
  stroke-width: 20px;
}

.page__wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  min-height: 0;
  border-radius: 16px;
  max-height: none;
  height: 500px;
}
.page__wrapper-footer {
  clear: both;
  background-color: #fff;
}
.page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: "Averta", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4;
  color: #262626;
  background-color: #fff;
  overscroll-behavior-y: contain;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .edp {
    position: fixed;
    width: 100%;
    height: 100%;
  }
  .edp.branch-banner-is-active {
    height: calc(100% - 64px);
    margin-top: 64px;
  }
}

.is-mobile-scrollable {
  overflow: auto;
}
@media (min-width: 46.25em) {
  .is-mobile-scrollable {
    overflow: hidden;
  }
}

.edp-exposed-filters .quick-picks__title {
  font-size: 1.8rem;
  line-height: 1.2;
  margin-bottom: 5px;
  font-weight: bold;
  text-transform: uppercase;
}
@media (min-width: 23.4375em) {
  .edp-exposed-filters .quick-picks__title {
    font-size: 2rem;
    line-height: 1.2;
  }
}
@media (min-width: 46.25em) {
  .edp-exposed-filters .quick-picks__title {
    font-size: 2.2rem;
    line-height: 1.2;
  }
}
.edp-exposed-filters .quick-picks__listings {
  background-color: #fff;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .edp-exposed-filters .quick-picks__listings {
    padding-bottom: 45px;
  }
}
.edp-exposed-filters .quick-picks__listings--presale-messages {
  overflow: auto;
  background-color: #f6f6f6;
}
.edp-exposed-filters .quick-picks__module-left {
  position: relative;
  z-index: 62;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
@media (min-width: 63.75em) {
  .edp-exposed-filters .quick-picks__module-left {
    -webkit-box-shadow: -1px 0 2px 0 #ebebeb;
    box-shadow: -1px 0 2px 0 #ebebeb;
  }
}
@media (min-width: 63.75em) {
  .edp-exposed-filters .quick-picks__module-left {
    border-left: 1px solid #ebebeb;
  }
}
.edp-exposed-filters .quick-picks__module-left--ism-btn {
  padding-bottom: 0;
}
@media (min-width: 63.75em) {
  .edp-exposed-filters .quick-picks__module-left--ism-btn {
    padding-bottom: 0;
  }
}

.edp-exposed-filters.branch-banner-is-active {
  height: calc(100% - 64px);
  margin-top: 64px;
}
.edp-exposed-filters .edp__main--ismqp-template {
  font-size: 1.2rem;
  line-height: 1.4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
}
@media (min-width: 23.4375em) {
  .edp-exposed-filters .edp__main--ismqp-template {
    font-size: 1.4rem;
    line-height: 1.4;
  }
}
@media (min-width: 46.25em) {
  .edp-exposed-filters .edp__main--ismqp-template {
    font-size: 1.6rem;
    line-height: 1.4;
  }
}
@media (min-width: 46.25em) {
  .edp-exposed-filters .edp__main--ismqp-template {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
}
.edp-exposed-filters .edp__main--ismqp-template h3 {
  font-size: 1.2rem;
  line-height: 1.4;
  font-weight: normal;
}
@media (min-width: 23.4375em) {
  .edp-exposed-filters .edp__main--ismqp-template h3 {
    font-size: 1.4rem;
    line-height: 1.4;
  }
}
@media (min-width: 46.25em) {
  .edp-exposed-filters .edp__main--ismqp-template h3 {
    font-size: 1.6rem;
    line-height: 1.4;
  }
}
.edp-exposed-filters .edp__main--ismqp-template .delimited-list--embedded {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #727272;
}
.edp-exposed-filters .edp__main--ismqp-template:after {
  display: block;
  clear: both;
  content: "";
}
.edp-exposed-filters .edp__map-container {
  background-color: #fff;
}
@media (max-width: 63.6875em) {
  .edp-exposed-filters
    .edp__map-container--image-header.edp__map-container--desktop {
    display: block;
  }
}
.edp-exposed-filters .edp__main--map {
  padding-top: 0;
}
@media (max-width: 46.1875em) {
  .edp-exposed-filters .ticket-bag--exposed {
    position: absolute;
    z-index: 64;
  }
}
@media (max-width: 63.75em) {
  .edp-exposed-filters .ticket-bag--exposed.is-hidden-under-md {
    display: none;
  }
}
.edp-exposed-filters .edp__main--split {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.edp-exposed-filters .edp__map-container--split {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  height: calc(32vh - 6.4rem);
  min-height: calc(32vh - 6.4rem);
  border-top: 1px solid #ebebeb;
}
@media (max-width: 46.1875em) {
  .edp-exposed-filters
    .edp__map-container--static:not(.edp__map-container--desktop) {
    height: auto;
  }
}

.edp-exposed-filters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
.clickable-path:hover {
  cursor: pointer;
}
