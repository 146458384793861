.sideBar {
  height: 100vh;
  width: 52.333px;
  background-color: rgba(0, 0, 0, 0.31);
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.05));
  transition: all 0.3s ease-in-out;
  z-index: 9999;
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
}

.sideBar:before {
  width: 52.333px;
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.31);
}

.sidebarHeader {
  display: flex;
  background-color: #090909;
  width: 100%;
  height: 51px;
  align-items: center;
  padding: 8px;
  gap: 8px;
}

.sidebarLogo {
  min-width: 37.333px;
  display: flex;
  justify-content: center;
}

.sidebarEmail {
  color: #e7e7e7;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sideBar nav a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  text-wrap: nowrap;
  margin: 0 0 20px;
  color: #e7e7e7;
  font-size: 13px;
  font-weight: 400;
}

.sideBar nav {
  padding: 20px 8px;
  z-index: 9;
  position: relative;
}

.sideBar .navItemIcon {
  min-width: 37.333px;
  display: flex;
  justify-content: center;
  padding: 7px 0px;
}

.MuiList-padding {
  padding-top: 0px;
  padding-bottom: 0px;
}

.MuiPopover-paper {
  outline: 0;
  position: absolute;
  max-width: calc(100% - 32px);
  min-width: 16px;
  max-height: calc(100% - 32px);
  min-height: 16px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #222222;
}

.MuiPaper-root {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #222222;
}
